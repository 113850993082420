<template>
  <div class="page" v-loading="loading">
    <el-form
      class="save-content"
      label-width="100px"
      :model="form"
      :rules="rules"
      ref="saveForm"
    >
      <el-form-item label="加入要求：">
        <el-radio-group v-model="form.enable">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标题别名：">
        <el-input style="max-width: 400px;" v-model="form.alias" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="填写内容：">
        <tinymce
          id="tinymce-editor"
          ref="editor"
          @choose="handleToolBarClick"
          v-model="form.cyc_content"
          style="max-width: 540px"
        />
      </el-form-item>
      <el-form-item label="上传文档：">
        <el-upload
          action="#"
          :before-remove="beforeRemove"
          :on-remove="handleRemove"
          :http-request="handleUpload"
          :before-upload="beforeUpload"
          :file-list="form.file_urls"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传word、excel、pdf、jpg、png文件，且不超过150M
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <FixedActionBar :z-index="2">
      <el-button  @click="cancel">取消</el-button>
      <el-button @click="$emit('openCode')">预览</el-button>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import Tinymce from '@/base/components/Editor/Tinymce'
import { getFileType } from '../../../../base/utils/tool'
import filesUpload from '../../../../base/utils/upload4'
import CycIntroducePage from '../../mixins/CycIntroducePage'
export default {
  mixins: [CycIntroducePage],
  components: { FixedActionBar, Tinymce },
  model: {
    prop: "previewForm",
    event: "setPreviewForm",
  },
  props: {
    previewForm:Object,
    formData: Object,
  },
  data() {
    return {
      key: 'requirement',
      loading: true,
      rules: {},
    }
  },
  computed: {
    form:{
      get(){
        console.log(this.$props);
        return this.$props.previewForm[this.key]
      },
      set(val){
        let formData = {...this.$props.previewForm}
        formData[this.key] = val
        this.$emit('setPreviewForm',formData)
      }
    }
  },
  methods: {
    saveConfig() {
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$emit('saveConfig', { key: this.key, config: this.form })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    cancel(){
      this.form = JSON.parse(JSON.stringify(this.$props.formData[this.key]))
    },
    /**
     * 富文本编辑器自定义工具栏按钮点击事件
     * @param {Object} e
     */
    handleToolBarClick(e) {
      switch (e.type) {
        case 'image':
          this.showImageList = true
          break
        case 'video':
          this.showVideoList = true
          break
        case 'audio':
          this.showAudioList = true
          break
        case 'import':
          this.isShowImportDialog = true
          break
        case 'html':
          this.isShowHtmlInput = true
          break
      }
    },
    beforeRemove(file) {
      /* 不符合条件的文件无法上传，选择后会触发 beforeRemove 和 handleRemove */
      console.log(file);
      if (!this.beforeUpload(file, true)) return
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      // this.$set(this.form, 'files', [])
      this.form.file_urls =  fileList
    },
    /**
     * @param file
     * @param silent  是否关闭错误提示
     * */
    beforeUpload(file, silent = false) {
      /* 文件类型判断 */
      const myFileType = getFileType(file?.name)
      const fileTypeSupported = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'jpg',
        'jpeg',
        'png',
      ].includes(myFileType)
      // 大小判断
      const is10M = file.size / 1024 / 1024 < 150
      if (!fileTypeSupported && !silent) {
        this.$message.error('上传文件类型仅支持 word、excel、pdf、jpg、png !')
      } else if (!is10M && !silent) {
        // 大小不匹配
        this.$message.error('上传文件大小不能超过 150MB !')
      }
      // 返回 false 阻断 true 正常上传
      return is10M && fileTypeSupported
    },
    handleUpload(file) {
      /* 上传成功后才将{name: '', url: ''}push到fileList中，会有成功图标 */
      // this.fileList.push({name: file.file.name, url: ''})
      filesUpload({
        configApi: '/admin/admin/media/requestUpload',
        data: file.file,
        progress: (n) => {
          // 更新 el-upload file-list 自带的进度条数值
          file.onProgress({ percent: n })
        },
      })
        .then((res) => {
          this.form.file_urls = [{name:res.data.title,url:res.data.url}, ...this.form.file_urls] 
        })
        .catch(() => {})
    },
  },
  watch: {
    '$props.formData.requirement': {
      handler(val) {
        this.form = JSON.parse(JSON.stringify(val))
        this.loading = false
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
}
</script>

<style></style>
